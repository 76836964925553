import { Meta, Links, Outlet, ScrollRestoration, Scripts, json, useLoaderData, ShouldRevalidateFunctionArgs } from "@remix-run/react";
import Footer from "~/components/layout/footer";
import Header from "~/components/layout/header";
import TransactionNotification from "~/components/transaction-notification";
import RadixProvider from "~/rdt/radixProvider";


export default function AppLayout() {
	return (
		<html className="" lang="en">
			<head>
				<Meta />
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width,initial-scale=1" />

				<meta property="og:locale" content="en_US" />
				<meta property="og:type" content="website" />
				<meta property="og:site_name" content="Astrolescent" />
				<meta property="og:image" content="https://astrolescent.com/assets/img/social-media-v3.png" />
				<meta property="og:image:type" content="image/png" />
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:site" content="@Astrolescent" />

				<meta name="theme-color" content="#2A163A" />
				<link rel="icon" type="image/png" href="/assets/img/icons/icon@32w.png" />
				<link rel="icon" sizes="192x192" href="/assets/img/icons/icon@192w.png" />
				<link rel="apple-touch-icon" href="/assets/img/icons/icon@152w.png" />
				<meta name="msapplication-square310x310logo" content="/assets/img/icons/icon@310w.png" />
				<Links />
			</head>
			<body className="max-md:bg-astro-mobile sm:bg-astrolescent min-h-full bg-[#2A163A] bg-cover bg-fixed lining-nums text-black selection:bg-astro selection:text-white">
				<RadixProvider>
					<div className="mx-4 my-8 md:container md:mx-auto md:max-w-lg">
						<Header />
						<main className="my-4 md:my-8">
							<Outlet />
							<TransactionNotification />
							<ScrollRestoration />
						</main>
						<Footer />
					</div>
				</RadixProvider>

				<Scripts />
			</body>
		</html>
	);
}
